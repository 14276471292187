import React from 'react'
import './Subscribe.css'

const Subscribe = () => {
  return (
   <section className="sub-wrapper">
    <div className="paddings innerWdth sub-container">
        <div className="flexCenter title">
            <span className="primaryText">Subcribe To Newsletter</span>
            <span className='secondaryText'>Subscribe to our newsletter to get amazing offers in future.</span>
        </div>
        <div className="flexCenter innerWidth paddings search-Bar">
            <input className='inputext' type="email" placeholder='    Enter your Email.'/>
            
            <button className='button2 button' >Get Start</button>
        </div>
    </div>
   </section>
  )
}

export default Subscribe